export const StringInput = ({ label, inputType, objId, defaultValue, dispatch }) => {
    return (
        <div>
            <label htmlFor={label}>{`${label}:`} </label>
            <input type={inputType} name={objId} defaultValue={defaultValue} 
                onBlur={event => dispatch({type: 'INPUT CHANGE', target: event.target.name, value: event.target.value })}/>
        </div>
    )
}

export const AreaInput = ({ label, inputType, objId, defaultValue, dispatch }) => {
    return (
        <div style={{display: 'grid'}}>
            <label htmlFor={label}>{`${label}:`} </label>
            <textarea 
                name={objId}
                style={{resize: 'none', height: '5em'}} 
                type={inputType} name={objId} 
                defaultValue={defaultValue} 
                onBlur={event => dispatch({type: 'INPUT CHANGE', target: event.target.name, value: event.target.value })}/>
        </div>
    )
}

export const Toggle = ({ label, inputType, objId, defaultValue, dispatch }) => {
    return (
        <div>
            <label htmlFor={label}>{`${label}:`} </label>
            <input type={inputType} name={objId} checked={defaultValue} 
                onChange={event => dispatch({type: 'INPUT CHANGE', target : event.target.name, value: event.target.checked })}/>
        </div>
    )
}

export const DateInput = ({ label, objId, defaultValue, dispatch }) => {
    const date = defaultValue ? new Date(defaultValue).toISOString().split('T')[0] : '';
    return (
        <div>
            <label htmlFor={label}>{`${label}:`} </label>
            <input type='date' name={objId} defaultValue={date} 
                onChange={event => dispatch({type: 'INPUT CHANGE', target : event.target.name, value: event.target.checked })}/>
        </div>
    )
}
