import { useState, useEffect, useReducer } from "react"
import { getStudents, updateStudent} from 'lib/fetch';
import { set, slice} from 'lodash';

export const useStudent = studentId => {
    const [student, studentDispatch ] = useReducer(studentReducer, {});
    const [ id, setId ] = useState(studentId);
    const { firstName, lastName } = student;

    useEffect(() => {
        getStudents({ query : {_id : studentId }}).then(res => {
            if(res.length > 0) {
                studentDispatch({ type: 'setStudent', newStudent: res[0]})
            }
        });
    },[id])

    useEffect(() => { console.log(student); },[student])

    const submit = event => {
        event.preventDefault();
        updateStudent(student).then(res => {
            console.log(res);
        })
    }

    return {
        student, 
        studentDispatch,
        submit,
        setId,
        name: `${firstName} ${lastName}`,
    }
}

const studentReducer = (state, action) => {
    const { type, objId, index, value, target, newObj } = action;
    let _state = {...state};
    switch(type) {
        case 'setStudent': 
            return {...action.newStudent}
        case 'INPUT CHANGE': 
            return {...state, [target] : value};
        case 'DELETE': 
            return {..._state, [objId] : [...slice(_state[objId], 0, index), ...slice(_state[objId], index+1)]};
        case 'ARRAY ADD': 
            if (!state[objId]) { return {...state, [objId] : ['']} }
            return {...state, [objId] : [...state[objId], '']};
        case 'ARRAY CHANGE': 
            set(_state, target, value);
            return {..._state};
        case 'OBJECT ADD': 
            if (!state[objId]) { return {...state, [objId] : [{...newObj}]} }
            return {...state, [objId] : [...state[objId], {...newObj}]}
        case 'OBJECT CHANGE': 
            set(_state, target, value);
            return {..._state};
        default: 
            throw new Error(`Unknown action type in studentReducer: ${type}`);
    }
}

