import { useEffect, useState } from 'react';
import Select from 'components/inputs/selection';
import { classes } from 'config/variable';
import SearchBox from 'components/Elements/searchBox';
import { useInput } from 'hooks/useInput';

const classList = Object.values(classes);

const selectActive = [
    {value: [true] , label: 'True' },
    {value: [false] , label: 'False' },
]

const selectClasses = [
    {value: classList , label: '' },
    {value: classes.pre, label: classes.pre },
    {value: classes.youth, label: classes.youth },
    {value: classes.adult, label: classes.adult },
]

const Filters = ({setQuery}) => {
    
    const { value : name, bind : nameBind, reset: nameReset } = useInput('');
    const [ isActive, setIsActive ] = useState(true);
    const [ cls, setCls ] = useState(classList);

    useEffect(() => {
        setQuery({ '$and' : [ 
            {isActive: { '$in' : isActive}},
            {'$or' : [
                {firstName:{'$regex': name, '$options': 'i'}},
                {lastName:{'$regex': name, '$options': 'i'}},
            ]}, 
            {'classes': {'$in' : cls}},
        ]});
        console.log('isActive', isActive);
        console.log('cls', cls);
    },[isActive, cls, name])

    const onActiveChange = e => {
        setIsActive(e.target.value.split(','));
    }

    const onClsChange = e => {
        setCls(e.target.value.split(','));
    }

    return (
        <>
            <SearchBox bind={nameBind} reset={nameReset}/>
            <label> isActive:
                <Select options={selectActive} onChange={onActiveChange}/>
            </label>
            <label> Classes:
                <Select options={selectClasses} onChange={onClsChange}/>
            </label>
        </>
    )
}

export default Filters;
