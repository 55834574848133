import styled from 'styled-components';

export const StyledTable = styled.table`
  // custom css goes here
    width: 100%;
`;

export const THead = styled.thead`
 // custom css goes here
`;

export const TFoot = styled.tfoot`
  // custom css goes here
`;

export const TBody = styled.tbody`
 // custom css goes here
`;

export const TR = styled.tr`
  // custom css goes here
//    &:hover {
//        background-color: #bebebe;
//    }
//
//    &: hover > {
//        border-color: #bebebe;
//    }
`;

export const TH = styled.th`
  // custom css goes here
    text-align: left;
`;

export const TD = styled.td`
  // custom css goes here
    
    span {
        width: 100%;
    }

    input, button {
        width: 100%;
        padding: 1ch;
    }
`;
