import { Fragment } from 'react';
import Table from 'components/Elements/table';
import { Button } from 'components/Elements/button';

const Attendance = ({attendanceObj}) => {

    const { attendance, } = attendanceObj;


    return (
        <>
            <h1>Attendance</h1>
            <h2>Attendance Count: {attendance.length}</h2>
            <Button onClick={() => console.log(attendance)} type='button'>Click me</Button>
        </>
    )
}

export default Attendance;
