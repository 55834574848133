import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { uploadImg } from 'lib/fetch';

const PictureInput = ({src, studentObj}) => {

    const [file, setFile] = useState(null);
    const { student: {_id} } = studentObj;
        
    const handleChange = event => {
        event.preventDefault();
        if(event.target.files.length !== 0) {
            setFile(event.target.files[0]);
        }
    }

    useEffect(() => {
        if (file) {
            const data = new FormData();
            data.append('profileImg', file);
            data.append('_id', _id);
            uploadImg(data).then(() => window.location.reload(true));
        }
    },[file]);

    return (
        <UploadInput>
        <label htmlFor="file-upload">
            <img src={src} style={{maxWidth: '100%'}}/>
        </label>
        <input id="file-upload" type="file" onChange={handleChange}/>
        </UploadInput>
    )
}

export default PictureInput;

const UploadInput = styled.div`
    label {
        grid-column: 1 / 3;
        display: flex;
        justify-content: center;
    }

    img {
        height: 450px;
        width: 300px;
        border-radius: 5px;
        box-shadow: 0 0 5px 0 black;
    }

    img:hover {
        box-shadow: 0 0 8px 0 black;
    }

    input[type=file] {
        display: none;
    }
`
