import { createGlobalStyle } from "styled-components";

export const mediaQuery = {
        mobile: '480px',
        tablet: '768px',
        medium: '1024px',
        large: '1200px',
}

const GlobalStyles = createGlobalStyle `
    :root {
        --c-primary: #303841;
        --c-secondary: #3a4750;
        --c-accent: #f6c90e;
        --bg-color: #eeeeee;
        --bg-white: #ffffff;

        --success: #27d3ab;
        --error: #fb425f;
    }

    @media (max-width: ${mediaQuery.mobile}) {
        body {
            font-size: 16px;
        }
    }

    @media (min-width: ${mediaQuery.mobile}) and (max-width: ${mediaQuery.tablet}) {
        body {
            font-size: 18px;
        }
    }

    @media (min-width: ${mediaQuery.tablet}) and (max-width: ${mediaQuery.medium}) {
        body {
            font-size: 20px;
        }
    }

    @media (min-width: ${mediaQuery.large}) {
        body {
            font-size: 22px;
        }
    }

    *,
    *::after,
    *::before {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    html {
        color: var(--c-primary);
        background-color: var(--bg-color);
    }

    body {
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
    }

    a { text-decoration: none; }

    li { list-style-type: none; }

    /* delete later*/
    button, input {
        background: none;
        color: inherit;
        border: 1px solid black;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
`;

export default GlobalStyles;

