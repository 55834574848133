

const Select = ({ options, onChange }) => {
    return (
        <select onChange={onChange}>
            {options.map( ({value, label}, i) => {
                return <option key={`${label} ${i}`} value={value}>{label}</option>
            })}
        </select>
    )
}

export default Select;
