import { Switch, Route, } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'components/Elements/links';
import Catalog from './catalog';
import Student from './student';
import Home from './home';

const links = [
    { path : '/admin', text: 'Home' }, 
    { path : '/admin/catalog', text: 'Catalog' }, 
    //{ path : '/admin/student', text: 'Student' }, 
]

const routes = [
    { path: '/admin/student/:id', component: Student, },
    { path: '/admin/student/', component: Catalog, exact: true, },
    { path: '/admin/catalog/', component: Catalog, },
    { path: '/admin/', component: Home, exact: true},
]

const Admin = () => {

    return (
        <Layout> 
            <Top>
                <h1> Migoto Admin </h1>
                <Link to='/'>Checkin </Link>
            </Top>
            <Nav> 
                <ul>
                    { links.map( ({ path, text}) => <li key={path}><Link to={path}>{text}</Link></li>)}
                </ul>
            </Nav>
            <Main>
                <Switch>
                    { routes.map( route => {
                        return <Route key={route} {...route} />
                    })}
                </Switch>
            </Main>
        </Layout> 
    )
}


export default Admin;

const Layout = styled.div`
    display: grid;
    grid-template: 'nav top top' min-content 'nav main main' 1fr / min-content 1fr 1fr;
    grid-gap: 0 2ch;
`

const Top = styled.div`
    grid-area: top;
    display: flex;
    justify-content: space-between;
    
    a {
        align-self: center;
    }
`

const Nav = styled.nav`
    grid-area: nav;
    grid-row-start: 2;
`

const Main = styled.main`
    grid-area: main;
    width: 100%;
    height: 100%;
`
