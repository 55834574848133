import styled from 'styled-components';
import { useStudentList } from "hooks/useStudentList";
import StudentCard from './studentCard';
import CheckinFilters from './checkinFilters';
import { classes } from 'config/variable';
import { useAttendance } from 'hooks/useAttendance';
import { useState, useEffect } from 'react';
import { dateTime } from 'lib/dateTime';

const Checkin = () => {

    const { studentList, setQuery } = useStudentList({ isActive : true, classes: {'$in' : classes.pre }, }, { firstName: 1, lastName: 1});
    const { attendance, setQuery : attendanceQuery } = useAttendance({always: false});
    const [ attendanceList, setAttendanceList ] = useState([]);

    useEffect(() => {
        setAttendanceList(attendance.map(({ studentId }) => studentId));
    }, [attendance]);

    useEffect(() => {
        attendanceQuery({studentId : { "$in" : studentList.map(({_id}) => _id)}, dateTime})
    }, [studentList]);

    return (
        <Page>
        <h1>Migoto Judo Checkin</h1>
        <CheckinFilters setQuery={setQuery} />
        <Container>
            { studentList.map( student => <StudentCard key={student._id} student={student} loggedIn={attendanceList.includes(student._id)}/> )}
        </Container>
        </Page>
    )
}

export default Checkin;

const Page = styled.div`
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    grid-gap: 1ch;

    h1 {
        margin: 0 auto;
    }

    .SearchBox input {
        background-color: #fff;
        border: 0px solid black;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        height: 2rem;
        min-width: 35ch;
        width: 95%;
    }

    .SearchBox button {
        background-color: #fff;
        border: 0px solid black;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        height: 2rem;
        padding: 0 1ch;
        width: 5%;
    }
`

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 300px);
    grid-auto-rows: 500px;
    justify-content: center;
    grid-gap: 10px;

    width: 95%;
    margin: 0 auto;
`
