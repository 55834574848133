import { StringInput, AreaInput, Toggle, DateInput, } from './inputs';
import ArrayInput from './inputs/arrayInput';
import ObjInput from './inputs/objInput';

export const studentInfo = {
    inputOrder: [
    { objId: "isActive", label: "isActive", defaultValue: "", InputComponent: Toggle, inputType : 'checkbox' },
    { objId: 'firstName', label: "First Name", defaultValue: "", InputComponent: StringInput, inputType : 'text' },
    { objId: 'lastName', label: "Last Name", defaultValue: "", InputComponent: StringInput, inputType : 'text' },
    { objId: 'dateOfBirth', label: "Date of Birth", defaultValue: "", InputComponent: DateInput, inputType : 'date' },
    { objId: 'gender', label: "Gender", defaultValue: "", InputComponent: StringInput, inputType : 'text' },
    { objId: 'studentId', label: "Student Id", defaultValue: "", InputComponent: StringInput, inputType : 'text' },
    { objId: 'judoStartDate', label: "Judo Start Date", defaultValue: "", InputComponent: DateInput, inputType : 'date' },
    { objId: 'email', label: "Email", defaultValue: "", InputComponent: StringInput, inputType : 'email' },
    { objId: "weight", label: "Weight", defaultValue: "", InputComponent: StringInput, inputType : 'text' },
    { objId: "rank", label: "Rank", defaultValue: "", InputComponent: StringInput, inputType : 'text' },
    { objId: "dateOfrank", label: "Date of Rank", defaultValue: "", InputComponent: DateInput, inputType : 'date' },
    { objId: "hours", label: "Hours", defaultValue: "", InputComponent: StringInput, inputType : 'text' },
    { objId: "alternateEmail", label: "Alternate Email", defaultValue: "", InputComponent: StringInput, inputType : 'text' },
    ],
}

export const tableInfo = {
    inputOrder: [
        { objId: 'classes', label: "Classes", defaultValue: [], InputComponent: ArrayInput, inputType : 'text' },
        { objId: 'phone', label: "Phone", defaultValue: [], InputComponent: ObjInput, inputType : 'tel' },
        { objId: "address", label: "Address", defaultValue: [], InputComponent: ObjInput, inputType : 'text' },
        { objId: "parentGuardian", label: "Parent Guardian", defaultValue: [], InputComponent: ObjInput, inputType : 'text' },
        { objId: 'emergencyContact', label: "Emergency Contact", defaultValue: "", InputComponent: ObjInput, inputType : 'text' },
        { objId: "memberships", label: "Memberships", defaultValue: [], InputComponent: ObjInput, ObjInput : 'text' },
        { objId: "notes", label: "Notes", defaultValue: "", InputComponent: AreaInput, inputType : 'text' },
    ],
}
