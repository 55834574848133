import { useState } from "react";
import Tabs from 'components/Elements/tabs'
import { useStudent } from 'hooks/useStudent';
import { useAttendance } from 'hooks/useAttendance';
import useStatement from 'hooks/useStatement';
import Profile from './profile';
import Attendance from './attendance';
import Statement from './statement';
import { useParams } from "react-router";

const tabs = [
    { tab: 'Profile' , Render: Profile},
    { tab: 'Attendance', Render: Attendance },
    { tab: 'Statement', Render: Statement },
//    { tab: 'Email', Render: Profile },
 //   { tab: 'Reports', Render: Profile },
]

const Student = () => {

    const [ activeTab, setActiveTab ] = useState('Profile');
    const { id } = useParams();
    const attendanceObj = useAttendance({studentId: id});
    const studentObj = useStudent(id);
    const statementObj = useStatement(id);

    return (
        <>
        <h2>{`${studentObj.name}`}</h2>
        <Tabs activeTab={activeTab} onClick={setActiveTab}>
            {tabs.map( ({ tab, Render }) => {
                return <div key={tab} tab={tab}><Render {...{ studentObj, statementObj, attendanceObj }}/></div>
            })}
        </Tabs>
        </>
    ) 
}

export default Student;
