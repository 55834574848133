import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStudentList } from 'hooks/useStudentList';
import { useAttendance } from 'hooks/useAttendance';
import { classes } from 'config/variable';

const Home = () => {
    
    const today = new Date()+1;
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday = new Date(yesterday.toISOString().split('T')[0]);
    const otherFilter = { dateTime : { '$gte' : yesterday, '$lte' : today }}
    
    const { attendance : pre, } = useAttendance({otherFilter}, {dateTime: -1});
    const { attendance : youth, } = useAttendance({ judoClass : classes.youth });
    const { attendance : adult, } = useAttendance({ judoClass : classes.adult });
//    const { attendance : pre, } = useAttendance({...otherFilter, judoClass : classes.pre });
//    const { attendance : youth, } = useAttendance({...otherFilter, judoClass : classes.youth });
//    const { attendance : adult, } = useAttendance({...otherFilter, judoClass : classes.adult });

    useEffect(() => {
        console.log('pre', pre );
        console.log('youth', youth);
        console.log('adult', adult);
    },[adult])

    return (
        <>
            <h2> Students in Today's Class: {pre.length + youth.length + adult.length } </h2>
            <ClassContainer>
                <ClassDiv>
                        <h3> Pre </h3>
                </ClassDiv>
                <ClassDiv>
                        <h3>Youth</h3>
                </ClassDiv>
                <ClassDiv>
                        <h3>Adult</h3>
                </ClassDiv>
            </ClassContainer>
        </>
    )
}

export default Home;

const ClassDiv = styled.div`
    background-color: #b7b7b7;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1ch;
    overflow: auto;

    h3 {
        margin: 2ch 0;
    }
`

const ClassContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
`
