import './tabs.css';

const Tabs = ({activeTab, children, onClick}) => {
    return ( 
        <div className='Tabs'>
            <ul>
                {children.map( ({props: {tab}}) => {
                    return  (
                        <li key={tab} 
                            className={activeTab === tab ? 'tab-list-item tab-list-active' : 'tab-list-item'}
                            onClick={() => onClick(tab)}>{tab}</li>
                    )
                })}
            </ul>
            <div className='acitve-tab-window'>
                {children.map( ({props : { children: childChildren, tab }}) => {
                    if (activeTab === tab) {
                        return childChildren;
                    }
                })}
            </div>
        </div>
    )
}

export default Tabs;

