import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)`
    color: var(--link-color, blue);

    &:hover {
        color: var(--link-color, green);
    }
`
