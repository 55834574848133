import { Fragment, useRef } from 'react';
import { Table } from 'components/Elements/table';
import useSelection from 'hooks/useSelect';
import { objSchema } from '../objSchema';
import styled from 'styled-components';
import { Button } from 'components/Elements/button';

const ObjInput = ({ label, objId, defaultValue, dispatch}) => {
    const parentRef = useRef();

    const { schema, columns, newObj }= objSchema[objId];
    return (
        <fieldset ref={parentRef}> 
        <legend>{label}</legend>
        <Table> 
        <Table.Head>
            <TR length={schema.length}>
                <Table.TH> </Table.TH>
                {schema.map(({objId}) => {
                    return <Table.TH key={objId}>{objId}</Table.TH>
                })}
            </TR>
        </Table.Head>
        <Table.Body>
        {defaultValue.length > 0 && defaultValue.map( (item, index) => {
            return (
                <TR key={`${item}.${index}`} length={schema.length}>
                <Table.TD>
                <Button onClick={() => dispatch({type:'DELETE', objId, index})} type='button'>x</Button>
                </Table.TD>
                {schema.map(({objId: childObjId, inputType: childInputType, InputComponent}, childIndex)=> { 
                    return (
                        <Table.TD key={`${objId}.${index}.${childObjId}`}>
                            <InputComponent 
                            name={`${objId}.${index}.${childObjId}`}
                            dispatch={dispatch}
                            type={childInputType}
                            defaultValue={item[childObjId]}/>
                        </Table.TD>
                    )
                })}
                </TR>
            )
        })}
        </Table.Body>
        </Table> 
        <Button onClick={() => dispatch({type:'OBJECT ADD', objId, newObj})} type='button'>Add</Button>
        </fieldset>
    )
}

export default ObjInput;

const TR = styled.tr`
    display: grid;
    grid-template-columns: min-content repeat(${({length}) => length}, 1fr);

    td > * {
        height: 100%;
        border: 0px solid black;
        border-top-width: 1px;
        border-left-width: 1px;
    }
    
    &:last-child > td > * {
        border-bottom-width: 1px;
    }

    td:last-child > * {
        border-right-width: 1px;
    }
`
