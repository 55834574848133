import { useState, useEffect, } from 'react';
import { getStudents } from 'lib/fetch';
import Axios from 'axios';

export const useStudentList = (initialQuery = {}, initialSort = {}) => {
    const [ studentList, setStudentList ] = useState([]);
    const [ query, setQuery ] = useState(initialQuery);
    const [ sort, setSort ] = useState(initialSort);

    useEffect(() => {
        getStudents({query, sort}).then(res => setStudentList(res));
    },[query, sort])

    useEffect(() => console.log(studentList), [studentList]);

    const reset = () => {
        setQuery(initialQuery);
        setSort(initialSort);
    }

    return { 
        studentList, 
        reset,
        setQuery,
        setSort,
    }
}

