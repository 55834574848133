import styled from 'styled-components';
import { mediaQuery } from 'styles/Global';
//import Table from 'components/table';
import { studentInfo, tableInfo } from './pageLayout';
import PictureInput from './inputs/pictureInput';
import { useStudent } from 'hooks/useStudent';
import { Button } from 'components/Elements/button';

const Profile = ({ studentObj }) => {

    const { student, name, submit, studentDispatch, } = studentObj;

    return (
        <StudentForm onSubmit={submit}> 
            <StudentFieldset>
                <legend>{name}</legend>
                <PictureInput src={student.profileImg} studentObj={studentObj}/>
                { studentInfo.inputOrder.map( (props) => {
                    const { objId, label, InputComponent, defaultValue } = props;
                    return ( <InputComponent 
                        key={label}
                        dispatch={studentDispatch}
                        {...props} defaultValue={student[objId] ? student[objId] : defaultValue}/>
                    )
                })}
            </StudentFieldset>
            <Tables>
                { tableInfo.inputOrder.map( (props) => {
                    const { objId, InputComponent, defaultValue } = props;
                    return (
                        <InputComponent 
                        key={objId}
                        dispatch={studentDispatch}
                        {...props} defaultValue={student[objId] ? student[objId] : defaultValue}/>
                    )
                })}
            </Tables>
            <Button> Sumbit </Button>
        </StudentForm>
    )
}

export default Profile;

const ProfileImg = styled.img`
    width: 50%;
    grid-column-start: 1;
    grid-column-end: 3;
`

const StudentFieldset = styled.fieldset`
    div {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }
`

const Tables = styled.div`
    display: grid;
`

const StudentForm = styled.form`
    display: grid;
    grid-gap: 0 1ch;

    @media (min-width: ${mediaQuery.large}) {
        grid-template-columns: 1fr 3fr;
    }
    
    input {
        padding: 1ch 0 1ch 1ch;
    }
`
