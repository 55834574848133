import { Fragment, useRef } from 'react';
import { Table } from 'components/Elements/table';
import styled from 'styled-components';
import { Button } from 'components/Elements/button';


const ArrayInput = ({inputType, objId, label, defaultValue, dispatch}) => {
    const parentRef = useRef();
    return (
        <fieldset ref={parentRef}> 
        <legend>{label}</legend>
        <Table>
        <Table.Head>
        <TR>
        <Table.TD> </Table.TD>
        <Table.TD>Class Name</Table.TD>
        </TR>
        </Table.Head>
        <Table.Body>
        {defaultValue.length > 0 && defaultValue.map( (item, index) => {
            return (
                <TR key={`${item} ${index}`}>
                    <Table.TD>
                        <Button type='button' onClick={() => dispatch({type: 'DELETE', objId, index})}>x</Button>
                    </Table.TD>
                    <Table.TD>
                        <input 
                            name={`${objId}[${index}]`} 
                            type={inputType} 
                            defaultValue={item} 
                            onBlur={event => dispatch({type: 'ARRAY CHANGE', event})} />
                    </Table.TD>
                </TR>
            )
        })}
        </Table.Body>
        </Table>
        <Button type='button' onClick={() => dispatch({type: 'ARRAY ADD', objId})}>Add</Button>
        </fieldset> 
    )
}
export default ArrayInput;

const TR = styled.tr`
    display: grid;
    grid-template-columns: min-content 1fr;

    td > * {
        border: solid black;
        border-width: 1px 0px 1px 1px;
    }

    td:last-child > * {
        border-width: 1px 1px 1px 1px;
    }
`
