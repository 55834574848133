import axios from 'axios';
import apiRoutes from 'config/apiRoutes';
const { student, attendance, statement, mail, img } = apiRoutes;

const _get = (url, body = {}) => { 
    return axios({
        method: 'get', 
        url : url, 
        params: body,
    }).then(res => res.data);
} 

const _post = (url, body, options = {}) => { 
    return axios({
        method: 'post', 
        url : url, 
        data: body,
    }, options).then(res => res.data);
}

export default { get: _get, post: _post }

export const getStudents = (body, options = {}) => {
    return _post(`${student}`, body, options);
}

export const updateStudent = query => {
    return _post(`${student}/update`, query );
}

export const uploadImg = ( query ) => {
    return _post(`${img}/upload`, query );
}

export const getAttendance = body => {
    return _post(`${attendance}`, body );
}

export const logAttendance = body => {
    return _post(`${attendance}/log/`, body);
}

export const getStatement = query => {
    return _post(`${statement}`, query );
}

export const makePayment = query => {
    return _post(`${statement}/pay`, query );
}

export const email = body => {
    return _post(`${mail}`, body);
}

export const getIMG = () => {
    return _get('/img/');
}
