import { useState, useEffect } from 'react';
import { Link } from 'components/Elements/links';
import { useStudentList } from 'hooks/useStudentList';
import Filters from './filters';
import { Table } from 'components/Elements/table';
import TH from './components/tableTH';
import styled from 'styled-components';

const Catalog = () => {

    const { studentList, setQuery, setSort } = useStudentList({ isActive: true}, { lastName: 1, firstName: 1});

    return (
        <div>
        <Filters setQuery={setQuery}/>
        <Table>
        <Table.Head>
        <TR>
            <Table.TH> <input type='checkbox' /> </Table.TH>
            <TH setSort={setSort} filter='firstName'>First Name</TH>
            <TH setSort={setSort} filter='lastName'>Last Name</TH>
            <Table.TH>Status</Table.TH>
            <Table.TH>Classes</Table.TH>
            <Table.TH>Add</Table.TH>
        </TR>
        </Table.Head>
        <Table.Body>
            {studentList.map(({_id, firstName, lastName, isActive, classes}) => {
                return (
                    <TR key={_id}>
                        <Table.TD><input type='checkbox' /></Table.TD>
                        <Table.TD>{firstName}</Table.TD>
                        <Table.TD>{lastName}</Table.TD>
                        <Table.TD>{isActive ? 'Active' : 'Inactive'}</Table.TD>
                        <Table.TD>{classes}</Table.TD>
                        <Table.TD><Link to={`/admin/student/${_id}`}>Edit</Link></Table.TD>
                    </TR>
                )
            })}
        </Table.Body>
        </Table>
        </div>
    )

}

export default Catalog;

const TR = styled.tr`
    display: grid;
    grid-template-columns: 2ch repeat(${({children}) => children.length -2 }, 1fr) min-content;

    th {
        text-align: left;
    }

    &:hover {
        background-color: #bebebe;
    }

    td {
        border: solid black;
        border-width: 1px 0px 0px 0px;
    }
`
