export const StringInput = ({dispatch, name, type, defaultValue}) => {
    return (
        <input 
        name={name}
        onBlur={event => dispatch({type: 'OBJECT CHANGE', target: event.target.name, value: event.target.value})} 
        type={type}
        defaultValue={defaultValue}/>
    )
}

export const DateInput = ({dispatch, name, defaultValue}) => {
    const date = defaultValue ? defaultValue.split('T')[0] : defaultValue;
    return (
        <StringInput 
        name={name}
        type='date'
        dispatch={dispatch}
        defaultValue={date}
        />
    )
}
