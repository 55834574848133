import styled from "styled-components";

export const ButtonStyles = styled.button`
    color: var(--button-color, #000);
    border: 1px solid var(--button-color, #000);
    background-color: var(--button-background, #efefef);
    
    &:hover {
        color: var(--button-background, #efefef);
        background-color: var(--button-color, #000);
    }

`

export const Input = styled.input`

`

export const TextArea = styled.textarea`

`
