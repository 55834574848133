import { Table } from 'components/Elements/table';

const StatementTable = ({statement}) => {

    const header = ['amount', 'date', 'memo', 'type' ];

    return (
        <Table style={{gridArea: 'table'}}>
            <Table.Head>
                <Table.TR>
                    {header.map(text => <Table.TD key={text}>{text}</Table.TD>)}
                </Table.TR>
            </Table.Head>
            <Table.Body>
            {statement && statement.map(({amount, date, memo, type}, i) => {
                return (
                    <Table.TR key={`${i}-${amount}-${date}`}>
                        <Table.TD>{amount}</Table.TD>
                        <Table.TD>{date.split('T')[0]}</Table.TD>
                        <Table.TD>{memo}</Table.TD>
                        <Table.TD>{type}</Table.TD>
                    </Table.TR>
                )
            })}
            </Table.Body>
        </Table>
    )
}

export default StatementTable;
