import { useEffect, useState } from 'react';

const TH = ({filter, children, setSort}) => {

    const [sortDirection, setDirection] = useState(true);

    useEffect(() => {
        console.log(sortDirection);
    }, [sortDirection]);

    const onClick = () => {
        setDirection(!sortDirection);
        setSort({ [filter] : sortDirection ? 1 : -1 });
    }

            //{`${children} ${sortDirection ? '+' : '-'}`}
    return (
        <th onClick={onClick}>
            {children}
        </th>
    )
}

export default TH;
