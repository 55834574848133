import { BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import GlobalStyles from './styles/Global';
import Checkin from './pages/checkin';
import Admin from './pages/admin';

function App() {

  return (
    <BrowserRouter basename="">
        <div className="App">
        <Switch>
            <Route path='/admin' component={Admin} />
            <Route path='/' exact component={Checkin} />
        </Switch>
        </div>
        <GlobalStyles />
    </BrowserRouter>
  );
}

export default App;

