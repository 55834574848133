import { useState, useEffect } from "react";
import { getAttendance } from 'lib/fetch';


export const useAttendance = ( initialQuery = {}, initialSort = {} ) => {
    const [ attendance, setAttendance ] = useState([]);
    const [ query, setQuery] = useState(initialQuery);
    const [ sort, setSort] = useState(initialSort);

    useEffect(() => {
        getAttendance({ query, sort }).then(res => setAttendance(res));
    },[query])

    useEffect(() => {
        console.log(attendance);
    },[attendance]);

    return {
        attendance, 
        setQuery, 
        setSort,
    }
}

