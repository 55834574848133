import styled from 'styled-components';
import { logAttendance } from 'lib/fetch';

const StudentCard = ({ student, loggedIn }) => {
    if (!student) {
        return null;
    }

    const dateTime = new Date().toISOString();

    const { _id, firstName, lastName, } = student

    const handleAttendance = () => {
        logAttendance({studentId: _id, dateTime}).then( res => {
            if (res.deletedCount) {
                window.alert(`${firstName} ${lastName} logged out :(`);
            }
            else {
                window.alert(`${firstName} ${lastName} logged in!`);
            }
        });
    }

    return (
        <StudentCardDiv className={loggedIn ? 'logged' : ''}>
            <img src={student.profileImg} onClick={handleAttendance}/>
            <p> {firstName}  {lastName} </p>
        </StudentCardDiv>
    )

};

export default StudentCard;

const StudentCardDiv = styled.div`
    display: grid;
    grid-template-rows: 1fr min-content;
    padding: 1ch 0;
    box-shadow: 0 0 5px 0 black;
    border-radius: 5px;
    overflow: hidden;

    & .logged {
        box-shadow: 0 0 5px 0 green;
    }

    & .logged:hover {
        box-shadow: 0 0 8px 0 green;
    }
    
    &:hover {
        box-shadow: 0 0 8px 0 black;
    }

    * {
       align-self: center;
    }

    img {
        height: 100%;
        width: 100%;
    }  

`

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`

