import { StringInput, DateInput } from './inputs/extraObjInputs';

export const objSchema = {
    memberships : {
        columns: 'repeat(3, 1fr)',
        schema : [
            {objId: 'membershipName', label: 'Membership Name', InputComponent: StringInput, defaultValue: '', inputType : 'text', },
            {objId: 'membershipNumber', label: 'Membership Number', InputComponent: StringInput, defaultValue: '', inputType : 'text', },
            {objId: 'expirationDate', label: 'Expiration Date', InputComponent: DateInput, defaultValue: '', inputType : 'date', }, ],
        newObj: { 'membershipName' : '', 'membershipNumber' : '', 'expirationDate': '', },
    },
    parentGuardian : {
        columns: 'repeat(4, 1fr)',
        schema: [
            {objId: 'name', label: 'Name', InputComponent: StringInput, defaultValue: '', inputType : 'text', },
            {objId: 'relationship', label: 'Relationship', InputComponent: StringInput, defaultValue: '', inputType : 'text', },
            {objId: 'email', label: 'Email', InputComponent: StringInput, defaultValue: '', inputType : 'text', },
            {objId: 'phone', label: 'Phone', InputComponent: StringInput, defaultValue: '', inputType : 'tel', }, ],
        newObj: { 'name': '', 'relationship': '', 'email': '', 'phone': '', },
    },
    address : {
        columns: 'repeat(5, 1fr)',
        schema: [
            {objId: 'address1', label: 'Addreses 1', InputComponent: StringInput, defaultValue: '', inputType : 'text', },
            {objId: 'address2', label: 'Addreses 2', InputComponent: StringInput, defaultValue: '', inputType : 'text', },
            {objId: 'city', label: 'City', InputComponent: StringInput, defaultValue: '', inputType : 'text', },
            {objId: 'state', label: 'State', InputComponent: StringInput, defaultValue: '', inputType : 'text', }, 
            {objId: 'zip', label: 'Zip Code', InputComponent: StringInput, defaultValue: '', inputType : 'text', }, ],
        newObj: { 'address1' : '', 'address2' : '', 'city' : '', 'state' : '', 'zip' : '', },
    },
    emergencyContact: {
        columns: 'repeat(3, 1fr)',
        schema: [
            {objId: 'name', label: 'Name', InputComponent: StringInput, defaultValue: '', inputType : 'text', },
            {objId: 'relationship', label: 'Relationship', InputComponent: StringInput, defaultValue: '', inputType : 'text', },
            {objId: 'phone', label: 'Phone Number', InputComponent: StringInput, defaultValue: '', inputType : 'tel', }, ],
        newObj: { 'name' : '', 'relationship' : '', 'phone' : '', },
    },
    phone: {
        columns: 'repeat(2, 1fr)',
        schema: [
            {objId: 'number', label: 'Phone Number', InputComponent: StringInput, defaultValue: '', inputType : 'tel', },
            {objId: 'notes', label: 'Notes', InputComponent: StringInput, defaultValue: '', inputType : 'text-box', }, ],
        newObj: { 'number' : '', 'notes' : '', },
    },
}

//button checkbox color date datetime-local email file hidden image month number password radio range reset search submit tel text time url week
