import { useEffect, useState } from 'react';
import SearchBox from 'components/Elements/searchBox';
import { useInput } from 'hooks/useInput';
import { classes } from 'config/variable';
import styled from 'styled-components';

const CheckinFilters = ({setQuery}) => {
    const nameSearch = useInput('');
    const { value: name, reset } = nameSearch;
    const [ cls, setCls ] = useState(classes.pre);

    useEffect(() => {
        setQuery({ '$and' : [ 
            {isActive: true},
            {'$or' : [
                {firstName:{'$regex': name, '$options': 'i'}},
                {lastName:{'$regex': name, '$options': 'i'}},
            ]}, 
            {'classes': {'$in' : cls}},
        ]
        })
    },[name, cls])

    return (
        <FilterDiv> 
        <SearchBox {...nameSearch} />
        <ButtonDiv>
        {Object.values(classes).map( classname => {
            return <button className={cls===classname ? 'active' : ''}key={classname} onClick={() => setCls(classname)}>{classname}</button>
        })}
        </ButtonDiv>
        </FilterDiv>
    )
}

export default CheckinFilters;

const FilterDiv = styled.div`
    display: grid;
    grid-gap: 1ch;
`

const ButtonDiv = styled.div`
    display: flex;
    justify-content: space-around;

    button {
        font-size: 100%;
        background-color: var(--bg-color);
        border: solid var(--c-secondary);
        border-width: 0px 1px 0 1px;
        width: 80%;
        padding: 1ch 0;
    }

    button.active {
        background-color: var(--c-secondary);
        color: var(--bg-white);
    }
`
