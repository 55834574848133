import { useEffect, useReducer } from "react";
import { getStatement } from 'lib/fetch';


const useStatement = (id) => {

    const [statement, statementDispatch] = useReducer(statementReducer, {});

    useEffect(() => {
        if (id) {
            getStatement({'studentId': id}).then(res => statementDispatch({type: 'setStatement', payload: res}));
        }
    },[id])

    useEffect(() => {
        console.log(statement);
    }, [statement]);

    return {
        statement,
        statementDispatch,
    }

}

export default useStatement;

const statementReducer = (state, action) => {
    const { type, payload } = action;

    switch(type) {
        case 'setStatement': 
            return {...state, statement: payload}
        default: 
            throw new Error(`Unknown action type in studentReducer: ${type}`);
    }
}
