const SearchBox = ({bind, reset}) => {
    return (
        <div className='SearchBox'>
            <input {...bind } />
            <button onClick={reset}>x</button>
        </div>
    )
}

export default SearchBox;
