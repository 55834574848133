import styled from 'styled-components';
import { makePayment } from 'lib/fetch';
import { useInput } from 'hooks/useInput';
import { useEffect } from 'react';
import { Button } from 'components/Elements/button';

const Payment = ({ id, monthlyMembershipFee }) => {
    const { value: amount, setValue: setAmount, bind: bindAmount } = useInput(0);
    const { value: memo, bind: bindMemo } = useInput('');
    const { value: date, bind: bindDate } = useInput(new Date().toISOString().split('T')[0]);

    useEffect(() => {
        if (monthlyMembershipFee) { setAmount(monthlyMembershipFee); }
    }, [monthlyMembershipFee])

    const onSubmit = () => {
        makePayment({
            studentId: id, 
            date, 
            type: 'payment',
            amount,
            memo,
        });

    }
    
    return (
        <Form onSubmit={onSubmit} style={{gridArea: 'payment'}}>
            <h3> Payment </h3>
            <label>Payment Amount</label>
            <input {...bindAmount} type='number'/>
            <label>Payment Date</label>
            <input {...bindDate} type='date'/>
            <label>Payment Memo</label>
            <textarea {...bindMemo} />
            <Button>Make Payment</Button>
        </Form>
    )
}

export default Payment;

const Form = styled.form`
    display: grid;
`
