import styled from 'styled-components';
import Payment from './payment';
import StatementTable from './statementTable';
import { Button } from 'components/Elements/button';

const Statement = ({ studentObj, statementObj }) => {

    const { student, submit, studentDispatch } = studentObj;
    const { statement, } = statementObj;

    return (
        <>
        <h2> Statement </h2>
        <Layout>
        <form onSubmit={submit} style={{display: 'grid', gridArea:'monthly'}}>
            <label> 
                Balance: $<input name='balance' type='number' defaultValue={student.balance} 
                    onChange={event => studentDispatch({type: 'INPUT CHANGE', target: event.target.name, value: event.target.value })}/> 
            </label>
            <label> 
                Monthly Membership: $<input name='monthlyMembershipFee' type='number' defaultValue={student.monthlyMembershipFee} 
                    onChange={event => studentDispatch({type: 'INPUT CHANGE', target: event.target.name, value: event.target.value })}/> 
            </label>
            <Button onClick={submit}>Update</Button>
        </form>
        <Payment id={student._id} monthlyMembershipFee={student.monthlyMembershipFee}/>
        <StatementTable statement={statement.statement}/>
        </Layout>
        </>
    )

}

export default Statement;

const Layout = styled.div`
    display: grid;
    grid-template: 'monthly payment' min-content 'table table' min-content / 1fr 2fr;
`
